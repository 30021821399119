
import {
  Component,
  Vue,
} from 'vue-property-decorator';
import useTenantSettingsStore from '@/stores/tenantSettings';

// Lazy loading imports
const UsernamePassword = () => import(
  /* webpackChunkName: "login-username-password" */
  /* webpackPrefetch: false */
  '@/components/entry/login/UsernamePassword.vue'
);
const WebAuthn = () => import(
  /* webpackChunkName: "login-web-authn" */
  /* webpackPrefetch: false */
  '@/components/entry/login/WebAuthn.vue'
);

type Type = 'UsernamePassword' | 'WebAuthn'

@Component({
  name: 'Login',
  components: {
    UsernamePassword,
    WebAuthn,
  },
})
export default class Login extends Vue {
  private tenantSettings = useTenantSettingsStore();

  private match = (name: string): Type => {
    switch (name) {
      case 'password':
        return 'UsernamePassword';
      case 'webauthn':
        return 'WebAuthn';
      default:
        throw Error(`No component available for ${name}`);
    }
  };
}
